import React from 'react';

export default function NhsOptionRadio({ formFields, options, optionHints=null, optionValue = null, optionName = null, formik, onChange = null, ...props }) {
  const label = formFields[props.name]?.Label;
  const hint = formFields[props.name]?.Hint;
  const id = optionValue ?? 'Id';
  const name = optionName ?? 'Name';
  return (
    <fieldset>
      { label && 
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
          <h2 className="nhsuk-label">{label}</h2>
        </legend>
      }

      { hint && <div className="nhsuk-hint nhsuk-radios__hint ps-0" id={`${props.name}Hint`}>{hint}</div> }

      { formik.touched[props.name] && formik.errors[props.name] ? 
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors[props.name]}
        </span> : null
      }
    
      <div className="nhsuk-form-group">
        <div className="nhsuk-radios" id={props.id || props.name}>
          { options && options.map(o =>
            <div key={o[id]} className="nhsuk-radios__item">
              <input 
                type="radio" 
                id={`${props.name}-${o[id]}`} 
                {...props} 
                onChange={ onChange ?? formik.handleChange} 
                checked={formik.values[props.name] === o[id]?.toString()}
                value={o[id]?.toString()}
                className='nhsuk-radios__input' />

              <label htmlFor={`${props.name}-${o[id]}`} className="nhsuk-label nhsuk-radios__label">{o[name]}</label>
              {optionHints && optionHints.filter(x => x.id == o[id]).map(o => <div className="nhsuk-hint nhsuk-radios__hint" id={`${props.name}-${o[id]}optionHints`}>{o.hint}</div>) }
            </div>
          )}
        </div>
      </div>   
    </fieldset>  
  );
};