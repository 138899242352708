import { SessionStorageKeys } from '../../_shared/shared.enums'
import { PolicyIds, RoleIds, UserPaths } from "./user.enums";
import { Policies, User } from "./user.models";
import { Option } from '../../_shared/shared.models';
import { Redirect } from '../../_shared/shared.functions';
import { ClearVaccinatorLocationStorage } from '../vaccinator-location/vaccinatot-location.function';

export function ClearUserStorage(): void { 
  sessionStorage.removeItem(SessionStorageKeys.User);
}

export function roleList(): Option[] {
  return [
    { Id: RoleIds.Administrator, Name: 'Administrator'},
    { Id: RoleIds.LeadAdministrator, Name: 'Lead administrator'},
    { Id: RoleIds.Recorder, Name: 'Recorder'},
    { Id: RoleIds.RegionalAdministrator, Name: 'Regional administrator'}
  ];
}

export function SetPolicies(user: User): void {
    const policies = [
    {
      Id: PolicyIds.CanManageUsers,
      Roles: [RoleIds.LeadAdministrator]
    }
  ] as Policies[];

  if (user.Roles) {
    user.Policies = [];
    for(const p of policies ) {
      if (user.Roles.some(r => p.Roles.includes(r))) user.Policies.push(p.Id);
    }
  }
}

export function SignOut() {
  Promise.all([
      ClearUserStorage(),
      ClearVaccinatorLocationStorage()
  ]).then(() => {
      Redirect(UserPaths.ApiLogout);
  }).catch((error) => {
      console.error("Error during sign out:", error);
  });
}