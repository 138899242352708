import React, { useEffect, useState } from "react";
import { Button, SummaryList } from 'nhsuk-react-components';
import { initialValues, ManageUserPages, UserFormValues } from '../user/pages/ManageUsersPage';
import { User } from '../user/user.models';
import { AccountStatusIds } from '../user/user.enums';
import { useUser } from '../user/UserProvider';
import EmailPreview from "./email-preview/email-preview";
import userManagementService from "../../_shared/services/user-management/userManagement.service";
import { Clinician } from "../vaccination/vaccination.models";
import useOktaService from "../../_shared/services/okta/okta.service";
import { NotifyTemplateType } from "../../_shared/shared.enums";
import { matchRoleWithRoleId } from "../../_shared/shared.functions";
import { Loading } from "../../_shared/components/Loading";

export const isUserClinician = (clinical: string) => {
  if (clinical.toLowerCase() === "yes") return true;
  else return false;
};

const OrganisationUserSummary: React.FC<{
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<ManageUserPages>>;
  userFormValues: UserFormValues;
  setUserFormValues: React.Dispatch<React.SetStateAction<UserFormValues>>;
  setFocusField: React.Dispatch<React.SetStateAction<string>>;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  clinicians: Clinician[];
  setClinicians: React.Dispatch<React.SetStateAction<Clinician[]>>;
}> = ({
  setTitle,
  setCurrentPage,
  userFormValues,
  setUserFormValues,
  setFocusField,
  users,
  setUsers,
  clinicians,
  setClinicians
}) => {
    const [hoveredField, setHoveredField] = useState<string | null>(null);
    const [emailTemplate, setEmailTemplate] = useState<NotifyTemplateType>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const currentUser = useUser();

    const fields = [
      {
        key: "Name",
        value: `${userFormValues.firstName} ${userFormValues.lastName}`,
        field: "firstName"
      },
      { key: "Email address", value: userFormValues.email, field: "email" },
      { key: "Clinical", value: userFormValues.clinician, field: "clinician" },
      {
        key: "Permission level",
        value: userFormValues.permission,
        field: "permission"
      }
    ];

    useEffect(() => {
      const fetchEmailTemplate = async (email) => {
        try {
          setLoading(true);
          const exists = await useOktaService.checkOktaUserExistence$(email);

          const templateType = exists
            ? NotifyTemplateType.NewUserSignUpWithoutOktaActivation
            : NotifyTemplateType.NewUserSignUpWithOktaActivation;

          setEmailTemplate(templateType);
        } catch (error) {
          console.error('Error checking if user exists:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchEmailTemplate(userFormValues.email);
    }, [userFormValues.email]);

    const confirmAndSend = async () => {
      const { firstName, lastName, email, permission, clinician } = userFormValues;

      const user: User = {
        UserId: null,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Roles: [matchRoleWithRoleId(permission)],
        OrganisationId: currentUser?.OrganisationId,
        AccountStatusId: AccountStatusIds.Pending,
        Clinical: isUserClinician(clinician),
        LastLoginDate: null,
      };

      try {
        const res = await userManagementService.addOrEdit$(user, "Add");
        if (res) {
          setCurrentPage(ManageUserPages.ManageUsers);
          setTitle(ManageUserPages.ManageUsers);
          setUserFormValues(initialValues)
          const addedToUsers = users.concat(user);
          const sortedUsers = addedToUsers.sort((a, b) => {
            const nameA = `${a.LastName.toLowerCase()} ${a.FirstName.toLowerCase()}`;
            const nameB = `${b.LastName.toLowerCase()} ${b.FirstName.toLowerCase()}`;

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          setUsers(sortedUsers);
          if (user.Clinical) {
            //For user-management only the email matters here
            const newClinician: Clinician = {
              ClinicianId: '0',
              Name: `${firstName} ${lastName}`,
              Roles: [0],
              Email: email,
              IsDeleted: false,
              IsFullClinician: user.Clinical
            }
            setClinicians([...clinicians, newClinician]);
          }
        }
      } catch (error) {
        console.error('Error adding or editing user:', error);
      }
    };

    return (
      <div className='nhsuk-grid-row nhsuk-grid-column-two-thirds'>
        <SummaryList>
          {fields.map(({ key, value, field: fieldName }) => (
            <SummaryList.Row key={key}>
              <SummaryList.Key>{key}</SummaryList.Key>
              <SummaryList.Value>{value}</SummaryList.Value>
              <SummaryList.Actions>
                <button
                  className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "default-color"
                    }`}
                  onMouseEnter={() => setHoveredField(fieldName)}
                  onMouseLeave={() => setHoveredField(null)}
                  onClick={() => {
                    setTitle(ManageUserPages.UserForm);
                    setCurrentPage(ManageUserPages.UserForm);
                    setFocusField(fieldName);
                  }}
                >
                  Change
                  <span className="nhsuk-u-visually-hidden">
                    {" "}
                    {key.toLowerCase()}
                  </span>
                </button>
              </SummaryList.Actions>
            </SummaryList.Row>
          ))}
        </SummaryList>
        <p>{userFormValues.firstName} will be sent this welcome email with information about activating an account:</p>

        {loading &&
          <Loading message={"Loading email preview"} />
        }

        {!loading &&
          <EmailPreview templateType={emailTemplate} email={userFormValues.email} />
        }

        <Button
          className="nhsuk-button"
          data-module="nhsuk-button"
          onClick={confirmAndSend}
        >
          Confirm and send
        </Button>
      </div>
    );
  };

export default OrganisationUserSummary;
