import React from 'react';
import { GetAge, IsoToDateFornat, FormatNhsNumber } from '../../_shared/shared.functions';
import { GenderName } from './patient.functions';


export default function PatientDetails({patient}) {
  return (
    <>
      { patient && 
        <div className='nhsuk-card patient-details'>
          <div className='nhsuk-card__content p-3'>
            <h3>Patient Details</h3>  
            
            <dl className="nhsuk-summary-list fs-6">        
              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  Name
                </dt>
                <dd className="nhsuk-summary-list__value">
                  {patient.FirstName} {patient.LastName}
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  NHS number
                </dt>
                <dd className="nhsuk-summary-list__value">
                                  {FormatNhsNumber(patient.NhsNumber)}
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  Date of birth
                </dt>
                <dd className="nhsuk-summary-list__value">
                  <div>{IsoToDateFornat(patient.DateOfBirth)} </div>
                  <div>(aged {GetAge(patient.DateOfBirth)})</div>
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  Gender
                </dt>
                <dd className="nhsuk-summary-list__value">
                  {GenderName(patient.GenderId)}
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  Telephone
                </dt>
                <dd className="nhsuk-summary-list__value">
                  {patient.Telephone}
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">
                  Address
                </dt>
                <dd className="nhsuk-summary-list__value">
                {patient.Address && patient.Address + ','} { patient.Postcode }
                </dd>
              </div>
            </dl>          
          </div>
        </div>
      }    
    </>
  );
}