import { Region } from "../../user/user.models";

export interface Organisation {
  OrganisationId: number;
  Name: string;
  Code: string;
  NhsdCode?: string
  NhsdTerm?: string;
  Type?: string;
  OrganisationStatusId?: number;
  OrganisationStatus?: OrganisationStatus;
  RegionId?: number;
}

export interface OrganisationStatus{
  id: number;
  Name: string;
}

export interface OrganisationORD {
  Name: string;
  Id?: number
  OrgId: string;
  Status?: string;
  OrgRecordClass?: string;
  PostCode?: string;
  LastChangeDate?: string;
  PrimaryRoleId?: string;
  PrimaryRoleDescription?: string;
  OrgLink?: string;
  Town?: string;
  AddressLine1?: string;
}

export interface OrganisationStatus{
  id: number;
  Name: string;
}

export interface OrganisationExistenceInfo{
  Exists: boolean;
  Region?: Region
}


export enum OrganisationStatusIds{
  Invited = 1,
  AwaitingContract = 2,
  Active = 3
}