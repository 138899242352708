import { appAxios } from '../../_shared/axios';
import { BatchDto } from '../batch/batch.models';
import { AddEditSiteVaccineDto, SiteVaccines, AddEditSiteVaccineBatchDto } from '../site-vaccines/site-vaccines.models';
import { Option } from "../../_shared/shared.models";

export class SiteVaccinesService {
    private basePath = 'api/site/';
    private siteVaccines: string;
    private options: string;
    private nhsdOrganisation: string

    async options$(id: any): Promise<any> {

        return await appAxios.get(`${this.basePath}options?id=${id}`)
            .then(res => {
                this.options = JSON.stringify(res.data);
                return res.data
            });
    }

    async getAllSiteVaccines$(): Promise<any> {
        return await appAxios.get(`${this.basePath}GetAllSiteVaccines`)
            .then(res => {
                this.siteVaccines = JSON.stringify(res.data);
                return res.data
            });
    }

    async submitSiteVaccines$(sitesVaccines: AddEditSiteVaccineDto): Promise<SiteVaccines[]> {
        this.clearCache();

        return await appAxios.post(`${this.basePath}SubmitSiteVaccines`, sitesVaccines)
            .then(res => {
                return res.data
            });
    }

    async submitSiteVaccineBatch$(batch: AddEditSiteVaccineBatchDto): Promise<any> {
        return await appAxios.post(`${this.basePath}SubmitSiteVaccineBatch`, batch)
            .then(res => {
                return res.data
            });
    }

    async editSiteVaccineBatch$(siteVaccineBatch: BatchDto): Promise<any> {
        return await appAxios.post(`${this.basePath}EditSiteVaccineBatch`, siteVaccineBatch)
            .then(res => {
                return res.data
            });
    }

    async depleteSiteVaccineBatch$(siteVaccineBatch: BatchDto): Promise<any> {
        return await appAxios.post(`${this.basePath}DepleteSiteVaccineBatch`, siteVaccineBatch)
            .then(res => {
                return res.data
            });
    }

    async reactivateSiteVaccineBatch$(siteVaccineBatch: BatchDto): Promise<any> {
        return await appAxios.post(`${this.basePath}ReactivateSiteVaccineBatch`, siteVaccineBatch)
            .then(res => {
                return res.data
            });
    }

    async nhsdOrganisations$(orgSearchParameter: string): Promise<Option[]> {
        return await appAxios.get(`${this.basePath}NhsdOrganisations?orgSearchParameter=${orgSearchParameter}`)
            .then(res => {
                return res.data
            });
    }

    async nhsdOrganisation$(odsCode: string): Promise<any> {
        if (this.nhsdOrganisation?.includes(odsCode)) return JSON.parse(this.nhsdOrganisation);

        return await appAxios.get(`${this.basePath}NhsdOrganisation?odsCode=${odsCode}`)
            .then(res => {
                this.nhsdOrganisation = JSON.stringify(res.data);
                return res.data
            }).catch(function (error) {
                console.error(error);
            });
    }

    private clearCache() {
        this.options = undefined;
    }

    static get instance() { return siteVaccinesService }
}

const siteVaccinesService = new SiteVaccinesService();

export default siteVaccinesService;