import React, { useEffect } from 'react';
import { BackLink, Label } from 'nhsuk-react-components';
import OrganisationUserList from '../../user-management/OrganisationUserList';
import OrganisationUserForm from '../../user-management/OrganisationUserForm';
import OrganisationUserSummary from '../../user-management/OrganisationUserSummary';
import AccessControl from '../../../_shared/components/accessControl/AccessControl';
import { Clinician } from '../../vaccination/vaccination.models';
import { User } from '../user.models';
import { useUser } from '../UserProvider';
import { RoleIds } from '../user.enums';
import userManagementService from '../../../_shared/services/user-management/userManagement.service';
import { useNavigate } from 'react-router-dom';
import useDocumentTitle from '../../../_shared/hooks/useDocumentTitle';
import useAnalytics from '../../analytics/hooks/useAnalytics';

const fetchClinicians = async (organisationId: number): Promise<Clinician[]> => {
    try {
        return await userManagementService.getClinicians$(organisationId);
    } catch (error) {
        console.error("Failed to fetch clinicians");
        return [];
    }
};

const fetchUsers = async (organisationId: number): Promise<User[]> => {
    try {
        return await userManagementService.getUsers$(organisationId);
    } catch (error) {
        console.error("Failed to fetch users");
        return [];
    }
};

export enum ManageUserPages {
    ManageUsers = "Manage users",
    UserForm = "Add user",
    UserSummary = "Check and add user",
}

export interface UserFormValues {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    clinician: string;
    permission: string;
}

export const initialValues: UserFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    clinician: '',
    permission: ''
};

const getAnalyticsSegment = (currentPage: ManageUserPages, isEditing: boolean) : string | undefined => {
    switch (currentPage) {
        case ManageUserPages.UserForm:
            return isEditing ? 'edit' : 'add';
        case ManageUserPages.UserSummary:
            return 'check';
        default:
            return undefined;
    }
};

const ManageUsersPage: React.FC = () => {
    const currentUser = useUser();
    const navigate = useNavigate();
    useDocumentTitle(ManageUserPages.ManageUsers);
    const [title, setTitle] = React.useState<string>(ManageUserPages.ManageUsers);
    const [currentPage, setCurrentPage] = React.useState<ManageUserPages>(ManageUserPages.ManageUsers);
    const [userFormValues, setUserFormValues] = React.useState<UserFormValues>(initialValues)
    const [focusField, setFocusField] = React.useState<string>('');
    const [clinicians, setClinicians] = React.useState<Clinician[]>([]);
    const [users, setUsers] = React.useState<User[]>([]);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    useAnalytics([
        "service",
        "manage-users",
        getAnalyticsSegment(currentPage, isEditing),
    ]);

    useEffect(() => {
        if (currentUser && currentUser.OrganisationId) {
            setIsLoading(true);
            Promise.all([
                fetchClinicians(currentUser.OrganisationId),
                fetchUsers(currentUser.OrganisationId)
            ]).then(([clinicians, users]) => {
                setClinicians(clinicians);
                setUsers(users);
                setIsLoading(false);
            }).catch(() => {
                navigate('/error');
            });
        }
    }, [currentUser]);

    const handleBackClick = () => {
        if (currentPage === ManageUserPages.UserForm) {
            setTitle(ManageUserPages.ManageUsers);
            setCurrentPage(ManageUserPages.ManageUsers);
            setUserFormValues(initialValues);
        }
        else if (currentPage === ManageUserPages.UserSummary) {
            setTitle(ManageUserPages.UserForm);
            setCurrentPage(ManageUserPages.UserForm);
        }
    }

    return (
        <AccessControl requiredRoles={[RoleIds.LeadAdministrator]} matchAllRoles={false}>
            <div className='nhsuk-grid-row'>
                {currentPage !== ManageUserPages.ManageUsers && <BackLink
                    asElement="button"
                    onClick={() => {
                        handleBackClick()
                    }}
                >
                    Back
                </BackLink>}
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
                    <h1 className="nhsuk-fieldset__heading">
                        {title}
                    </h1>
                </legend>

                {currentPage === ManageUserPages.ManageUsers && <OrganisationUserList
                    setTitle={setTitle}
                    setCurrentPage={setCurrentPage}
                    setUserFormValues={setUserFormValues}
                    users={users}
                    clinicians={clinicians}
                    setIsEditing={setIsEditing}
                    isLoading={isLoading} />}
                {currentPage === ManageUserPages.UserForm && <OrganisationUserForm
                    setTitle={setTitle}
                    setCurrentPage={setCurrentPage}
                    userFormValues={userFormValues}
                    setUserFormValues={setUserFormValues}
                    focusField={focusField}
                    setFocusField={setFocusField}
                    isEditing={isEditing}
                    users={users}
                    setUsers={setUsers}
                    clinicians={clinicians}
                    setClinicians={setClinicians} />}
                {currentPage === ManageUserPages.UserSummary && <OrganisationUserSummary
                    setTitle={setTitle}
                    setCurrentPage={setCurrentPage}
                    userFormValues={userFormValues}
                    setUserFormValues={setUserFormValues}
                    setFocusField={setFocusField}
                    users={users}
                    setUsers={setUsers}
                    clinicians={clinicians}
                    setClinicians={setClinicians} />}
            </div>
        </AccessControl>
    )
}

export default ManageUsersPage;