import React from 'react';

export default function NhsDateInput({ formFields, formik, ...props }) {
  const label = formFields[props.name]?.Label;
  const hint = formFields[props.name]?.Hint;
  return (
    <div className="nhsuk-form-group">
      { label && <label htmlFor={props.name} className="nhsuk-label">{label}</label> }

      { hint && <div className="nhsuk-hint nhsuk-radios__hint ps-0" id={`${props.name}Hint`}>{hint}</div> }
      
      { formik.touched[props.name] && formik.errors[props.name] && 
        <span className="nhsuk-error-message" id={`${props.name}Error`}>
          <span className="nhsuk-u-visually-hidden">Error:</span> {formik.errors[props.name]}
        </span>
      }

      <input type='date' id={props.name} {...props} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values[props.name]} className='nhsuk-select'/>
    </div>  
  );
};