import * as React from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Button } from 'reactstrap';
import useDocumentTitle from '../../_shared/hooks/useDocumentTitle';
import useAnalytics from '../analytics/hooks/useAnalytics';
import { AddVaccineAnalyticsSubCategories, SiteVaccinesAnalyticsPageNames, SiteVaccinesPageTitles, SiteVaccinesPaths } from './site-vaccines.enums';
import { HandleErrorFocusOnClickById, HandleErrorFocusOnClickByName, Redirect } from '../../_shared/shared.functions';
import { Paths } from '../../_shared/shared.enums';
import { SiteVaccines } from './site-vaccines.models';
import { BatchDto } from '../batch/batch.models';

export default function AddVaccine() {
    useAnalytics([
        "service",
        SiteVaccinesAnalyticsPageNames.PrimaryCategory,
        AddVaccineAnalyticsSubCategories.SubCategory1,
        AddVaccineAnalyticsSubCategories.SubCategory2
    ]);
    useDocumentTitle(SiteVaccinesPageTitles.AddVaccine);

    const location = useLocation();
    const navigate = useNavigate();

    const redirect = () => {
        Redirect(Paths.Home);
    }

    const optionsStateData = location && location.state ? location.state[0] as any : redirect();
    const userSitesStateData = location && location.state ? location.state[1] as any : redirect();
    const siteVaccinesStateData = location && location.state ? location.state[2] as SiteVaccines[] : redirect();
    const batchStateData = location && location.state ? location.state[3] as BatchDto : redirect();

    const [options] = React.useState(optionsStateData as any);
    const [userSites] = React.useState(userSitesStateData);
    const [siteVaccines] = React.useState(siteVaccinesStateData as SiteVaccines[]);
    const [batch, setBatch] = React.useState(batchStateData as BatchDto);

    const [showVaccines, setShowVaccines] = React.useState(false);
    const [vaccineError, setVaccineError] = React.useState(null);

    const [vaccineProgramElementId, setVaccineProgramElementId] = React.useState(null);
    const [vaccineElementId, setVaccineElementId] = React.useState(null);

    React.useEffect(() => {
        if (batch && batch.VaccineProgramId) {
            setShowVaccines(true);
        }
    }, [batch]);

    const formik = useFormik({
        initialValues: {
            Hidden: 'true',
            VaccineProgramId: batch ? batch?.VaccineProgramId?.toString() : '',
            VaccineId: batch ? batch?.VaccineId?.toString() : ''
        },

        validationSchema: object().shape({
            Hidden: string(),
            VaccineProgramId: string().required("Select the vaccine"),
            VaccineId: string().when(['VaccineProgramId', 'Hidden'], {
                is: (VaccineProgramId, Hidden) => VaccineProgramId && Hidden === 'false',
                then: schema => schema.required("Select the vaccine product")
            })
        }),

        enableReinitialize: true,

        onSubmit: (values) => {
            if (values.VaccineProgramId && !values.VaccineId && values.Hidden === 'true') {
                setVaccineError(`Select the vaccine product`);
                return;
            }

            if (!vaccineError) {
                let batchData = values as unknown as BatchDto;

                batchData = batch ? batch : batchData

                batchData.VaccineProgramId = Number(values.VaccineProgramId);
                batchData.VaccineId = Number(values.VaccineId);

                batchData.VaccineProgram = options?.VaccinePrograms.find(vp => vp.Id === Number(batch.VaccineProgramId))?.Name;
                batchData.Vaccine = options?.Vaccines.find(v => v.VaccineId === Number(batch.VaccineId))?.Name;

                setBatch(batchData);

                navigate(SiteVaccinesPaths.AddBatch, { state: [options, userSites, siteVaccines, batchData] });
            }
        }
    });

    const handleVaccineProgramChange = (e) => {
        setVaccineError(null);
        setShowVaccines(false);
        setVaccineProgramElementId(null);

        formik.setFieldValue('VaccineId', '');
        formik.setErrors({ VaccineId: null })

        var id = e.target.id
        setVaccineProgramElementId(id);

        setShowVaccines(true);
        formik.setFieldValue('Hidden', 'true');

        if (batch && batch.BatchNumber) {
            setBatch(prevState => ({
                ...prevState,
                'BatchNumber': '',
                'ExpiryDate': ''
            }));
        }
    }

    const handleVaccineChange = (e) => {
        setVaccineError(null);
        setVaccineElementId(null);

        formik.setErrors({ VaccineId: null });
        formik.setFieldValue('Hidden', 'false');

        var id = e.target.id
        setVaccineElementId(id);

        const vaccineId = Number(e.target.value);

        if (siteVaccines?.length > 0) {
            siteVaccines.forEach((siteVaccine: SiteVaccines) => {
                if (siteVaccine.VaccineProgramId === Number(formik.values.VaccineProgramId) && siteVaccine.Site === batch.Site && vaccineId === siteVaccine.VaccineId) {
                    setVaccineError(`${batch.Site} already has ${siteVaccine.Vaccine}`);
                    return;
                }
            })
        }
    }

    return (
        <>
            {location && location.state ?
                <>
                    <div className="nhsuk-back-link">
                        <Link className="nhsuk-back-link__link" to={{ pathname: SiteVaccinesPaths.AddSite }}
                            state={[options, userSites, siteVaccines, batch]}><svg className="nhsuk-icon nhsuk-icon__chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" height="24" width="24">
                                <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                            </svg> Back
                        </Link>
                    </div>

                    <div className="nhsuk-grid-row mt-3">
                        <div className="nhsuk-grid-column-full">

                            {((formik.touched['VaccineProgramId'] && formik.errors['VaccineProgramId']) || (formik.touched['VaccineId'] && formik.errors['VaccineId']) || vaccineError) &&
                                <>
                                    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert">
                                        <h2 className="nhsuk-error-summary__title" id="error-summary-title">
                                            There is a problem
                                        </h2>
                                        <div className="nhsuk-error-summary__body">
                                            <ul className="nhsuk-list nhsuk-error-summary__list">
                                                {formik.touched.VaccineProgramId && formik.errors.VaccineProgramId && vaccineProgramElementId ?
                                                    <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickById(e, vaccineProgramElementId) })}>{formik.errors.VaccineProgramId}</Link></li>
                                                    :
                                                    <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'VaccineProgramId') })}>{formik.errors.VaccineProgramId}</Link></li>
                                                }

                                                {formik.touched.VaccineId && formik.errors.VaccineId && vaccineElementId ?
                                                    <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickById(e, vaccineElementId) })}>{formik.errors.VaccineId}</Link></li>
                                                    :
                                                    <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickByName(e, 'VaccineId') })}>{formik.errors.VaccineId}</Link></li>
                                                }

                                                {vaccineError &&
                                                    <li className="nhsuk-error-message"><Link to='#' onClick={((e) => { HandleErrorFocusOnClickById(e, vaccineElementId) })}>{`${vaccineError}`}</Link></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }

                            <form onSubmit={formik.handleSubmit}>
                                <fieldset className="nhsuk-fieldset mb-3">
                                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                                        <h1 className="nhsuk-fieldset__heading">
                                            Choose vaccine
                                        </h1>
                                    </legend>
                                </fieldset>

                                <div className={"nhsuk-form-group " + ((formik.touched['VaccineProgramId'] && formik.errors['VaccineProgramId']) ? "nhsuk-form-group--error" : "")}>
                                    <fieldset className="nhsuk-fieldset" aria-describedby="select-vaccine-hint">
                                        <label className="nhsuk-label" htmlFor="VaccineProgramId">
                                            Select the vaccine type
                                        </label>

                                        {formik.touched.VaccineProgramId && formik.errors.VaccineProgramId ?
                                            <span key="VaccineProgramIdErrorSpan" className="nhsuk-error-message" id="VaccineProgramIdError">
                                                <span key="VaccineProgramVisuallyHiddenErrorSpan" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.VaccineProgramId.toString()}
                                            </span> : null
                                        }

                                        <div key="VaccineProgramRadios" className="nhsuk-radios">
                                            {options?.VaccinePrograms && options?.VaccinePrograms.map(vp => <div key={vp.Id} className="nhsuk-radios__item">
                                                <input className="nhsuk-radios__input"
                                                    id={"VaccineProgramId" + vp.Id} name="VaccineProgramId"
                                                    type="radio"
                                                    checked={formik.values.VaccineProgramId === vp.Id.toString() ? true : false}
                                                    value={vp.Id.toString()}
                                                    onChange={(e) => { handleVaccineProgramChange(e); formik.handleChange(e); }}
                                                    onBlur={(e) => { handleVaccineProgramChange(e); formik.handleBlur(e); }}
                                                />

                                                <label key="VaccineProgramRadiosLabels" className="nhsuk-label nhsuk-radios__label" htmlFor={"VaccineProgramId" + vp.Id}>
                                                    {vp.Name}
                                                </label>
                                            </div>
                                            )}
                                        </div>
                                    </fieldset>
                                </div>

                                {showVaccines ?
                                    <>
                                        <div className={"nhsuk-form-group " + (((formik.touched['VaccineId'] && formik.errors['VaccineId']) || vaccineError) ? "nhsuk-form-group--error" : "")}>
                                            <div key="VaccinesLabelDiv" className="mb-3">
                                                {formik.values.VaccineProgramId &&
                                                    <label className="nhsuk-label">Select the vaccine product</label>
                                                }

                                                {formik.touched.VaccineId && formik.errors.VaccineId ?
                                                    <span key="SpanVaccineIdError" className="nhsuk-error-message" id="VaccineIdError">
                                                        <span key="VaccineIdVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> {formik.errors.VaccineId}
                                                    </span> : ''
                                                }

                                                {vaccineError ?
                                                    <span key="SpanVaccineError" className="nhsuk-error-message" id="VaccineError">
                                                        <span key="VaccineVisuallyHiddenError" className="nhsuk-u-visually-hidden">Error:</span> {vaccineError}
                                                    </span>
                                                    : ''
                                                }

                                                <div key="VaccinesRadio" className="nhsuk-radios">
                                                    {options && options.Vaccines?.filter(x => x.VaccineProgramId === Number(formik.values.VaccineProgramId)).map(v =>
                                                        <div key={v.VaccineId + 'VaccinesRadio'} className="nhsuk-radios__item">
                                                            <input className="nhsuk-radios__input"
                                                                id={"VaccineId" + v.VaccineId}
                                                                name="VaccineId"
                                                                type="radio"
                                                                checked={formik.values.VaccineId === v.VaccineId?.toString() ? true : false}
                                                                value={v.VaccineId?.toString()}
                                                                onChange={(e) => { handleVaccineChange(e); formik.handleChange(e); }}
                                                                onBlur={(e) => { handleVaccineChange(e); formik.handleBlur(e); }}
                                                            />

                                                            <label key="VaccinesRadioFieldLabel" className="nhsuk-label nhsuk-radios__label" htmlFor={"VaccineId" + v.VaccineId}>
                                                                {v.Name}
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </> : ''
                                }

                                <div className="mt-3">
                                    <Button type="submit" className="nhsuk-button">Continue</Button>
                                </div>

                            </form>
                        </div>
                    </div>

                </>
                :
                redirect()
            }
        </>
    )
}