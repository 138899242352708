import React, { useEffect, useState } from "react";
import { OrganisationORD } from "./models/organisation.models";
import { RegionalUserPages } from "../user/pages/RegionalUserPage";
import { BackLink, Button, SummaryList } from "nhsuk-react-components";
import regionalService from "../../_shared/services/regional/regional.service";
import { toTitleCase } from "../../_shared/shared.functions";

const createLineBreaks = (text: string) => {
    const parts = text.split('<br>');
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {index < parts.length - 1 && <br />}
        </React.Fragment>
    ));
};

interface RegionalOrganisationSummaryProps {
    setCurrentPage: React.Dispatch<React.SetStateAction<RegionalUserPages>>;
    setSelectedOrganisation: React.Dispatch<React.SetStateAction<OrganisationORD | null>>;
    selectedOrganisation: OrganisationORD | null;
}

interface SummaryFields {
    key: string;
    value: string;
}

const mapSummaryFields = (field: OrganisationORD): SummaryFields[] => {
    return [
        {
            key: "Name",
            value: toTitleCase(field.Name)
        },
        {
            key: "Type",
            value: toTitleCase(field.PrimaryRoleDescription) || ''
        },
        {
            key: "ODS code",
            value: field.OrgId
        },
        {
            key: "Address",
            value: `${toTitleCase(field.AddressLine1) || ''} <br> ${toTitleCase(field.Town) || ''} <br> ${field.PostCode}`
        }
    ];
};

const RegionalOrganisationSummary: React.FC<RegionalOrganisationSummaryProps> = ({ setCurrentPage, setSelectedOrganisation, selectedOrganisation }) => {
    const [fields, setFields] = useState<SummaryFields[]>(mapSummaryFields(selectedOrganisation));

    useEffect(() => {
        const fetchOrganisations = async () => {
            if (!selectedOrganisation.AddressLine1 && !selectedOrganisation.Town) {
                const results = await regionalService.searchOrganisations$(selectedOrganisation.OrgId, true);
                setFields(mapSummaryFields(results[0]));
                setSelectedOrganisation(results[0]);
            }
        };
        fetchOrganisations();
    }, [selectedOrganisation.AddressLine1, selectedOrganisation.Town, selectedOrganisation.OrgId]);

    const handleClick = () => {
        setCurrentPage(RegionalUserPages.AddLeadUser)
    };


    return (
        <div className='nhsuk-grid-row'>
            <div className='nhsuk-grid-column-two-thirds'>
                <BackLink
                    asElement="button"
                    onClick={() => {
                        setCurrentPage(RegionalUserPages.FindAnOrganisation);
                    }}
                >
                    Back
                </BackLink>
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
                    <h1 className="nhsuk-fieldset__heading">
                        Organisation
                    </h1>
                </legend>
                <SummaryList>
                    {fields.map(({ key, value }) => (
                        <SummaryList.Row key={key}>
                            <SummaryList.Key>
                                {key}
                            </SummaryList.Key>
                            <SummaryList.Value>
                                {createLineBreaks(value)}
                            </SummaryList.Value>
                        </SummaryList.Row>
                    ))}
                </SummaryList>
                <Button onClick={handleClick} className='nhsuk-button nhsuk-u-margin-top-5' data-module="nhsuk-button">Continue</Button>
            </div>
        </div>
    )
}

export default RegionalOrganisationSummary;