import React, { useEffect, useMemo, useState } from "react";
import AccessControl from "../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user/user.enums";
import { Button } from "nhsuk-react-components";
import { Region } from "../user/user.models";
import { Organisation, OrganisationORD } from "./models/organisation.models";
import { LeadUserFormValues, RegionalUserPages } from "../user/pages/RegionalUserPage";
import NhsTable from "../../_shared/components/NHSUK/NhsTable";
import organisationService from "../../_shared/services/organisation/organisation.service";
import { Loading } from "../../_shared/components/Loading";
import { initialValues } from "../user/pages/ManageUsersPage";
import { toTitleCase } from "../../_shared/shared.functions";

interface TransformedOrganisation {
  OrganisationId: number;
  Name: string;
  Code: string;
  Status: string;
  Type: string;
}

export interface IRegionOverviewProps {
  region: Region;
  setCurrentPage: (page: RegionalUserPages) => void;
  setSelectedOrganisation: React.Dispatch<React.SetStateAction<OrganisationORD | null>>;
  setIsExistingOrganisation: React.Dispatch<React.SetStateAction<boolean>>;
  setLeadUserFormValues: React.Dispatch<React.SetStateAction<LeadUserFormValues>>;
  setAddLeadUserPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

const RegionOverview: React.FC<IRegionOverviewProps> = ({
  region,
  setCurrentPage,
  setSelectedOrganisation,
  setIsExistingOrganisation,
  setLeadUserFormValues,
  setAddLeadUserPageTitle
}) => {

  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrganisationsByRegion = async () => {
      try {
        const organisations = await organisationService.getOrganisationsByRegion$(region.Id);
        setOrganisations(organisations);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganisationsByRegion();
  }, [region.Id]);

  const transformOrganisationData = (organisations: Organisation[]): TransformedOrganisation[] => {
    return organisations.map(og => ({
      OrganisationId: og.OrganisationId,
      Name: toTitleCase(og.Name),
      Code: og.Code,
      Status: og.OrganisationStatus.Name,
      Type: toTitleCase(og.Type)
    }));
  };

  const transformedData = useMemo(() => {
    return organisations ? transformOrganisationData(organisations) : [];
  }, [organisations]);

  const headers = useMemo(() => [
    { displayName: 'Organisation', key: 'Name' },
    { displayName: 'ODS code', key: 'Code' },
    { displayName: 'Type', key: 'Type' },
    { displayName: 'Status', key: 'Status' }
  ], []);

  const handleRowClick = (rowData: TransformedOrganisation) => {
    setSelectedOrganisation({
      Id: rowData.OrganisationId,
      Name: rowData.Name,
      OrgId: rowData.Code
    })
    setCurrentPage(RegionalUserPages.OrganiatonOverview);
  };

  return (
    <AccessControl requiredRoles={[RoleIds.RegionalAdministrator]} matchAllRoles={false}>
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-two-thirds">
          <h1 className="nhsuk-heading-xl" tabIndex={0}>{region.Name}</h1>
          <p className="nhsuk-body-l">Invite an organisation to create an NHS Record a vaccination service (RAVS) account.</p>
          <p className="nhsuk-body-l">Once they’re set up, they can add other users and vaccines.</p>
          <Button onClick={() => {
            setCurrentPage(RegionalUserPages.FindAnOrganisation);
            setIsExistingOrganisation(false)
            setLeadUserFormValues(initialValues)
            setAddLeadUserPageTitle("Add a user")
          }}>Invite an organisation</Button>
        </div>
      </div>

      {loading ? (
        <Loading message="Loading added organisations" />
      ) : (
        organisations.length > 0 && (
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
              <NhsTable<TransformedOrganisation> targetColumn='Name' onRowClick={handleRowClick} tableCaption="Organisations added" headers={headers} data={transformedData} />
            </div>
          </div>
        )
      )}
    </AccessControl>
  );
};

export default RegionOverview;
