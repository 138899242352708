import { NhsdOrganisation, VaccinatorLocation, VaccinatorLocationDropdowns } from "./vaccinator-location.models";
import { appAxios } from '../../_shared/axios';
import { Option } from "../../_shared/shared.models";
import { SessionStorageKeys } from "../../_shared/shared.enums";
import { PrimaryRoleIds } from "./vaccinator-location.enum";
import { UserPaths } from "../user/user.enums";
import { Redirect } from "../../_shared/shared.functions";

export class VaccinatorLocationService {
    private basePath = 'api/vaccinatorlocation/';
    private options: string;
    private sites: string
    private nhsdOrganisation: string

    async options$(): Promise<VaccinatorLocationDropdowns> {
        if (this.options) return JSON.parse(this.options);

        return await appAxios.get(`${this.basePath}options`)
            .then(res => {
                this.options = JSON.stringify(res.data);
                return res.data
            }).catch(function (error) {
                console.error(error);
            });
    }

    async nhsdOrganisation$(odsCode: string): Promise<NhsdOrganisation> {
        if (this.nhsdOrganisation?.includes(odsCode)) return JSON.parse(this.nhsdOrganisation);

        return await appAxios.get(`${this.basePath}NhsdOrganisation?odsCode=${odsCode}`)
            .then(res => {
                this.nhsdOrganisation = JSON.stringify(res.data);
                return res.data
            }).catch(function (error) {
                console.error(error);
            });
    }

    async getSitesByOrganisationId$(id: any): Promise<any[]> {
        if (this.sites) return JSON.parse(this.sites);

        return await appAxios.get(`${this.basePath}vaccinationgeosites?id=${id}`)
            .then(res => {
                this.sites = JSON.stringify(res.data);
                return res.data
            }).catch(function (error) {
                console.error(error);
            });
    }

    getVaccinatorLocation(): VaccinatorLocation {
        return JSON.parse(sessionStorage.getItem(SessionStorageKeys.VaccinatorLocation));
    }

    setVaccinatorLocation(vaccinatorLocation: VaccinatorLocation): void {
        sessionStorage.removeItem(SessionStorageKeys.VaccinatorLocation);
        sessionStorage.setItem(SessionStorageKeys.VaccinatorLocation, JSON.stringify(vaccinatorLocation));
    }

    vaccinatorLocationExists(): boolean {
        if (this.getVaccinatorLocation()) return true;
        return false;
    }

    async nhsdOrganisations$(searchTerm: string): Promise<Option[]> {
        return await appAxios.get(`${this.basePath}NhsdOrganisations?primaryRoleId=${PrimaryRoleIds.CareHome}&searchTerm=${searchTerm}`)
            .then(res => {
                return res.data
            });
    }

    static get instance() { return vaccinatorLocationService }
}

const vaccinatorLocationService = new VaccinatorLocationService();

export default vaccinatorLocationService;