import { appAxios } from '../../_shared/axios';
import { PageType } from '../../_shared/shared.enums';
import { Option } from '../../_shared/shared.models';
import { Vaccination, VaccinationOptions } from './vaccination.models';

export class VaccinationService {
  private basePath = 'api/vaccination/';
  private options: string;
  private lastVaccination: Vaccination;
  private includeDeleted: boolean | null = null;
  private forceRefresh: boolean = false;

  async options$(includeDeleted: boolean = false): Promise<VaccinationOptions> {
    // If forceRefresh is false, and cached options exist, and includeDeleted matches, use the cached options
    if (!this.forceRefresh && this.options && (this.includeDeleted === includeDeleted || this.includeDeleted === null)) {
        this.includeDeleted = includeDeleted;
        return JSON.parse(this.options);
    }

    // Fetch fresh data from the API, since forceRefresh is true or cache conditions don't match
    return await appAxios.get(`${this.basePath}options?includeDeleted=${includeDeleted}`)
        .then(res => {
            this.options = JSON.stringify(res.data);
            this.includeDeleted = includeDeleted;
            this.forceRefresh = false;
            return res.data;
        });
  }


  forceRefreshOptions() {
    this.forceRefresh = true;
  }

  async vaccines$(siteId: string, includeDisabled: boolean, vaccinationDate: string = null): Promise<Option[]> {
    const vaccinationDateParam = vaccinationDate ? `&vaccinationDate=${vaccinationDate}` : '';
    const url = `${this.basePath}vaccines?siteId=${siteId}&includeDisabled=${includeDisabled}${vaccinationDateParam}`;

    return await appAxios.get(url)
      .then(res => res.data);
  }
  
  async get$(id: string): Promise<Vaccination> {
    return await appAxios.get(`${this.basePath}get?id=${id}`)
    .then(res => {
      for (var i in res.data) {
        res.data[i] = res.data[i]?.toString();
      }
      return res.data 
    });
  }


  async vaccinesWithActiveBatches$(siteId: string, includeDisabled: boolean, vaccinationDate: string = null, vaccineProgramId: string): Promise<Option[]> {
    const vaccinationDateParam = vaccinationDate ? `&vaccinationDate=${vaccinationDate}` : '';
    const url = `${this.basePath}VaccinesWithActiveBatches?siteId=${siteId}&vaccineProgramId=${vaccineProgramId}&includeDisabled=${includeDisabled}${vaccinationDateParam}`;

    return await appAxios.get(url)
      .then(res => res.data);
  }
  

  async addOrEdit$(pageType, vaccination: Vaccination): Promise<Vaccination> {
    if (pageType === PageType.Add)  this.lastVaccination = vaccination;

    for (var i in vaccination) {
      if (!vaccination[i]) {
        delete vaccination[i];
      }
    }
    
    return await appAxios.post(`${this.basePath}${pageType}`, vaccination)
    .then(res => {
      return res.data 
    });
  }

  async delete$(id: any): Promise<any> {
    return await appAxios.get<any>(`${this.basePath}Delete?id=${id}`)
    .then(res => {
      return res.data 
    });
  }

  getLastVaccination(): Vaccination {
    return this.lastVaccination;
  }

  static get instance() { return vaccinationService }
}

const vaccinationService = new VaccinationService();

export default vaccinationService;
