import React, { useEffect } from 'react';
import { Button, Label, Table } from 'nhsuk-react-components';
import { ManageUserPages, UserFormValues } from '../user/pages/ManageUsersPage';
import { Clinician } from '../vaccination/vaccination.models';
import { User } from '../user/user.models';
import { roleList } from '../user/user.functions';
import { Loading } from '../../_shared/components/Loading';
import { AccountStatusIds } from '../user/user.enums';
import { UserStatusConverter } from '../../_shared/shared.functions';

export const matchRoleIdWithRole = (roleIds: string[]): string | undefined => {
    const privilegeOrder = ["2", "1", "3"]; //Lead admin > Admin > Recoder
    const roles = roleList();

    // Filter the roles based on the provided roleIds and sort them by privilege
    const matchedRoles = roles
        .filter(role => roleIds.includes(role.Id))
        .sort((a, b) => privilegeOrder.indexOf(a.Id) - privilegeOrder.indexOf(b.Id));

    // Return the name of the role with the highest privilege
    return matchedRoles.length > 0 ? matchedRoles[0].Name : undefined;
}

const fullClinicianRoleCheck = (Roles: number[]): boolean => {
    return Roles.includes(1) && Roles.includes(2) && Roles.includes(3);
}

const OrganisationUserList: React.FC<{
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<ManageUserPages>>;
    setUserFormValues: React.Dispatch<React.SetStateAction<UserFormValues>>;
    users: User[];
    clinicians?: Clinician[];
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
}> = ({ setTitle, setCurrentPage, setUserFormValues, users, clinicians, setIsEditing, isLoading }) => {
    const [hoveredField, setHoveredField] = React.useState<number | null>(null);

    const onAddUser = () => {
        setTitle(ManageUserPages.UserForm);
        setCurrentPage(ManageUserPages.UserForm);
        setIsEditing(false);
    }

    const renderUserRows = () => {
        const rows = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const isClinician = user.Clinical ||
                (clinicians &&
                    clinicians.some(clinician => clinician.Email.toLocaleLowerCase() === user.Email.toLocaleLowerCase()
                        && !clinician.IsDeleted && (clinician.IsFullClinician || fullClinicianRoleCheck(clinician.Roles))));

            if (user.AccountStatusId === AccountStatusIds.Disabled) { continue; }
            rows.push(
                <Table.Row key={user.UserId} role="row">
                    <Table.Cell role="cell">{user.FirstName} {user.LastName} {isClinician && '(Clinician)'}</Table.Cell>
                    <Table.Cell role="cell">{user.Email}</Table.Cell>
                    <Table.Cell role="cell">{matchRoleIdWithRole(user.Roles)}</Table.Cell>
                    <Table.Cell role="cell">{UserStatusConverter(user.LastLoginDate)}</Table.Cell>
                    <Table.Cell role="cell">
                        <button
                            className={`anchor-style ${hoveredField === i ? 'hovered-color' : 'default-color'}`}
                            onMouseEnter={() => setHoveredField(i)}
                            onMouseLeave={() => setHoveredField(null)}
                            onClick={() => {
                                setTitle(ManageUserPages.UserForm);
                                setCurrentPage(ManageUserPages.UserForm);
                                setIsEditing(true);
                                setUserFormValues({
                                    id: user.UserId,
                                    firstName: user.FirstName,
                                    lastName: user.LastName,
                                    email: user.Email.toLocaleLowerCase(),
                                    clinician: isClinician ? 'Yes' : 'No',
                                    permission: matchRoleIdWithRole(user.Roles),
                                });
                            }}
                            aria-label={`Change details for ${user.FirstName} ${user.LastName} ${isClinician ? 'Clinician' : ''}`}
                        >
                            Change
                            <span className="nhsuk-u-visually-hidden">role for {' '}{`${user.FirstName} ${user.LastName}`}</span>
                        </button>
                    </Table.Cell>
                </Table.Row>
            );
        }
        return rows;
    }

    return (
        <>
            <Button onClick={onAddUser} className='nhsuk-button' data-module="nhsuk-button">Add user</Button>
            {isLoading && <Loading />}
            {(users && !isLoading) &&
                <Table responsive role="table" >
                    <caption className="nhsuk-table__caption table-caption-fix">Users</caption>
                    <Table.Head role="rowgroup">
                        <Table.Row role="row">
                            <Table.Cell role="columnheader">Name</Table.Cell>
                            <Table.Cell role="columnheader">Email address</Table.Cell>
                            <Table.Cell role="columnheader">Permission level</Table.Cell>
                            <Table.Cell role="columnheader">Status</Table.Cell>
                            <Table.Cell role="columnheader"><span className="nhsuk-u-visually-hidden">Edit user</span></Table.Cell>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body role="rowgroup">
                        {renderUserRows()}
                    </Table.Body>
                </Table>}
        </>
    )
}

export default OrganisationUserList;


